import { Button, Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default class StartPage extends React.Component {
    render(): React.ReactNode {
        return (
            <div style={{paddingLeft: '240px', paddingRight: '240px'}}>
                <Grid container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <h1 style={{maxWidth: '600px'}}>Introduction to Decision Tree Classifiers, Parallel Coordinates and Human-in-the-Loop-Learning</h1>
                    </Grid>
                    <Grid item>
                        <p style={{maxWidth: '600px'}}>
                        This set of tutorials gives an interactive introduction to Decision Tree Classifiers,
                        Parallel Coordinates and human-in-the-loop-learning.
                        Decision tree classifiers are machine learning models that can
                        predict what category an instance of data belongs to.
                        These are known as classification tasks.
                        We start by exploring the basics of how Decision Tree Classifiers works
                        using the <a href='https://archive.ics.uci.edu/ml/datasets/iris'>Iris dataset</a>,
                        one of the most well-known datasets for classification tasks.
                        </p>
                        <p style={{maxWidth: '600px'}}>
                        In later sections of the tutorial, we look at Parallel Coordinates, a technique
                        of visualising datasets with a large number of attributes and see how Parallel Coordinates
                        can help us when working with Decision Tree Classifiers.
                        Finally, we take a look at human-in-the-loop learning and see how humans
                        can build decision tree classifiers.
                        Click Continue to get started!                      
                        </p>
                    </Grid>
                    <Grid item>
                        <Link to='/dtcintro'>
                            <Button color='primary' variant='contained'>Continue</Button>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        )
    }
}