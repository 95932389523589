import { Button, Grid} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { ClassifierBuilder, Dataset, Instance, AxisSelectionRule, Rule, TreeNode } from '@eugene-gilmore/classifier-builder';
import { getID, infoBubble } from './Util';
import { Link } from 'react-router-dom';

interface State {
    stage : number
    iris : Dataset,
    irisTree : TreeNode,
    stageAfter200 : boolean,
}

export default class DecisionTreeWithPcoordIntroPage extends React.Component<{}, State> {
    state : State = {
        stage : 0,
        iris : new Dataset(''),
        irisTree : new TreeNode(true),
        stageAfter200 : true
    }

    componentDidMount() {
        axios.get('iris.csv').then(irisRes => {
            let iris = new Dataset(irisRes.data)
            iris.classes = ['Iris Setosa', 'Iris Versicolor', 'Iris Virginica']

            let tree = new TreeNode(true)
            tree.rules = [new Rule([new AxisSelectionRule(2, undefined, 2.45)])]
            tree.children = [new TreeNode(), new TreeNode()]
            tree.children[0].parent = tree
            tree.children[1].parent = tree
            tree.children[1].rules = [new Rule([new AxisSelectionRule(3, undefined, 1.75)])]
            tree.children[1].children = [new TreeNode(), new TreeNode()]
            tree.children[1].children[0].parent = tree.children[1]
            tree.children[1].children[1].parent = tree.children[1]
            this.setState({
                iris : iris,
                irisTree : tree,
            })
        })
    }

    incrementStage = (forward : boolean = true) => {
        let gotoNode = (node : TreeNode) => {
            let sn = this.state.irisTree.selectedNode()
            if(sn) {
              sn.selected = false
            }
            node.selected = true
        }
        let stage = this.state.stage + (forward ? 1 : -1)
        let newState : any = {
            stage: stage,
            stageAfter200: false
        }
        if(stage === 3) {
            gotoNode(this.state.irisTree.children[1])
        }
        else if(stage === 2) {
            gotoNode(this.state.irisTree)
        }
        else if(stage === 6) {
            gotoNode(this.state.irisTree.children[1])
        }
        else if(stage === 7) {
            gotoNode(this.state.irisTree.children[1].children[0])
        }
        newState['irisTree'] = this.state.irisTree
        this.setState(newState)
        setTimeout(() => {this.setState({stageAfter200 : true})}, 300)
    }

    onNodeClick = (node : TreeNode) => {
        let sn = this.state.irisTree.selectedNode()
        if(sn) {
          sn.selected = false
        }
        node.selected = true
        this.forceUpdate()
    }

    infoBubble(element : string, text : string, offsetX : number, offsetY : number, back : boolean = true) {
        return infoBubble(element, text, offsetX, offsetY, this.incrementStage, back)
    }
    
    render() {
        let stage = this.state.stage
        let highlight : Instance | undefined = undefined
        if(stage === 16 || stage === 17) {
            highlight = this.state.iris.instances[50]
        }
        else if(stage > 17) {
            highlight = this.state.iris.instances[140]
        }
        return (
            <div style={{paddingLeft: '240px', paddingRight: '240px'}}>
                <Grid container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <h1>Decision Tree Classifiers with Parallel Coordinates</h1>
                    </Grid>
                    {stage === 0  && <Grid item>
                        <p style={{maxWidth: '600px'}}>
                            Let's have a look now at how we can combine parallel coordinates 
                            with decision tree classifiers.
                        </p>
                    </Grid>}
                    {this.state.stage === 0 && <Grid item>
                        <Button color='primary' variant='contained' onClick={() => this.incrementStage()}>Continue</Button>
                    </Grid>}
                    <Grid item>
                    </Grid>
                </Grid>
                <div style={{ visibility: this.state.stage > 0 ? 'visible' : 'hidden',  }}>
                    <ClassifierBuilder dataset={this.state.iris} tree={this.state.irisTree}
                    visualiseRules={this.state.stage > 5} colouredNodes={true}
                    weight percentageWeight noAxisReorder noAxisRescale noControls
                    highlightedInstance={highlight}></ClassifierBuilder>
                </div>
                <Grid style={{ visibility: this.state.stage > 14 ? 'visible' : 'hidden'}} container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                    <div style={{display: 'flex'}}>
                            <Button style={{margin: '5px'}} color='primary' variant='contained' onClick={(e : any) => this.incrementStage(false)}>Back</Button>
                            <Link to='/hillintro'>
                                <Button style={{margin: '5px'}} color='primary' variant='contained' onClick={(e : any) => this.incrementStage(true)}>Continue</Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>

                {this.state.stage === 1 && this.state.stageAfter200 && this.infoBubble('dtcnode-root', 
                'Here we have the same decision tree classifier as before for the Iris data.', 1, 0.5, false)}
                {this.state.stage === 2 && this.infoBubble('pcoordaxis-sepal_length', 
                'Instead of a table, we use Parallel Coordinates to visualise the data used to build the tree.', 0, 0.5)}
                {this.state.stage === 3 && this.infoBubble('dtcnode-1', 
                'The decision tree classifier is linked with the Parallel Coordinates visualisation. When clicking on a node in the tree, only the portion of the dataset that reaches that node is shown in the Parallel Coordinates visualisation.', 1, 0.5)}
                {this.state.stage === 4 && this.infoBubble('pcoordaxis-petal_width', 
                'We can also use Parallel Coordinates to visualise the tests of the nodes in the tree.', 0, 0.5)}
                {this.state.stage === 5 && this.infoBubble('pcoordaxis-petal_width', 
                'Whenever an internal node is selected in the decision tree, the test for that node is shown as a green range on the relevant axes.', 0, 20)}

                {this.state.stage === 6 && this.infoBubble('dtcnode-root', 
                'To assist in determining if an instance reaches a particular leaf node, we introduce here an additional visualisation technique.', 1, 0.5)}
                {this.state.stage === 7 && this.infoBubble('dtcnode-01', 
                'When selecting a leaf node, the Parallel Coordinates visualisation now shows the tests of all nodes that must be visited to arrive at this node.', 1, 0.5)}
                {this.state.stage === 8 && this.infoBubble('pcoord-dot', 
                'When visualise a leaf node in this way, all lines in the parallel coordinates visualisation originate from a single point before the first axis.', 0, 0.5)}
                {this.state.stage === 9 && this.infoBubble('pcoordaxis-petal_length', 
                'The first axis in the parallel coordinates display corresponds to the first attribute used in the set of tests required to reach the leaf node.', 0, 0.5)}
                {this.state.stage === 10 && this.infoBubble('pcoordaxis-petal_width', 
                'Additional axes are displayed in order for all attributes involved in the splits from the root to the leaf node.', 0, 0.5)}
                {this.state.stage === 11 && this.infoBubble('pcoordaxis-sepal_length', 
                'After all relevant attributes appear in the parallel coordinates visualisation any remaining attributes are drawn as dotted axes in Parallel Coordinates.', 0, 0.5)}
                {this.state.stage === 12 && this.infoBubble('pcoordaxis-petal_length', 
                'For an instance to reach a leaf node, it must pass through the green ranges of all solid axes.', 0.49, 14)}
                {this.state.stage === 13 && this.infoBubble('pcoordaxis-petal_length', 
                'Any instance that doesn\'t match a range on an axis has it\'s line in Parallel Coordinates terminated at that axis.', 0.51, 28)}
                {this.state.stage === 14 && this.infoBubble('pcoordaxis-sepal_width', 
                'Once instances have passed through all axes involved in a split, all instances lines are dimmed.', 0.51, 20)}
            </div>
        )
    }
}