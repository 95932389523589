import React from "react";
import { Link } from "react-router-dom";
import NavBarMenu from "../NavBarMenu/NavBarMenu";
import './NavBar.css'

export default class NavBar extends React.Component {
    render(): React.ReactNode {
        let researchItems = [
            {name: 'Publications', path: '/publications'},
            {name: 'PhD Thesis', path: 'https://research-repository.griffith.edu.au/handle/10072/418633', external: true},
            {name: 'Introduction to Decision Trees', path: '/tutorial-start'}
        ]
        let projectItems = [
            {name: 'DTC Builder', path: '/hilltool'}
        ]
        return (
            <nav className='NavBar-root'>
                <ul className='NavBar-list'>
                    <li className='NavBar-item'>
                        <Link className='NavBar-button' to='/'>
                            Home
                        </Link> 
                    </li>
                    <NavBarMenu title='Research' items={researchItems}/>
                    <NavBarMenu title='Projects' items={projectItems}/>
                    <li className='NavBar-item'>
                        <a className='NavBar-button' download href='/cv.pdf'>
                            CV
                        </a> 
                    </li>
                    <li className='NavBar-item'>
                        <Link className='NavBar-button' to='/contact'>
                            Contact
                        </Link> 
                    </li>
                </ul>
            </nav>
        )
    }
}