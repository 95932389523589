import React from "react";
import './Footer.css'

export default function Footer() {
    return (
        <footer>
            <p>Eugene Gilmore &copy; 2022</p>
            <div>
              <a href='https://github.com/eugene-gilmore' className='fab fa-github'/>
              <a href='https://www.linkedin.com/in/eugene-gilmore' className='fab fa-linkedin'/>
            </div>
        </footer>
    )
}