import { ClassifierBuilder, Dataset, LoadDataButton, TreeNode, VisualisationType } from "@eugene-gilmore/classifier-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Grid, MenuItem, Modal, Select } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';


export default class HILLToolPage extends React.Component {

    state = {
        tree : new TreeNode(true),
        dataset : new Dataset(''),
        visStyle : VisualisationType.ParallelCoordinates,
        showInfo : false
    }

    onLoadData = (data : Dataset) => {
        this.setState({
            dataset : data,
            tree : new TreeNode(true)
        })
    }

    setVis = (event : any) => {
        this.setState({
            visStyle: event.target.value,
            tree: new TreeNode(true)
        })
    }

    showInfo = () => {
        this.setState({
            showInfo : true
        })
    }

    closeInfo = () => {
        this.setState({
            showInfo : false
        })
    }

    componentDidMount() {
        const cookies = new Cookies();
        let info = cookies.get('hill-software-info')
        if(info === undefined) {
            this.showInfo()
            var expire = new Date();
            expire.setDate(expire.getDate() + 365*10)
            cookies.set('hill-software-info', 1, {expires : expire})
        }
    }

    render(): React.ReactNode {
        return (
            <>
            <div style={{display: 'flex ', justifyContent: "flex-end"}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '20px', marginTop: '20px'}}>
                        <p style={{marginRight: '10px'}}>Visualisation Style:</p>
                        <Select onChange={this.setVis} value={this.state.visStyle}>
                            <MenuItem value={VisualisationType.ParallelCoordinates}>Parallel Coordinates</MenuItem>
                            <MenuItem value={VisualisationType.ScatterPlot}>Scatter Plot</MenuItem>
                        </Select>
                        <div style={{marginLeft: '30px'}}>
                            <LoadDataButton onLoadDataset={this.onLoadData}/>
                        </div>
                        <span style={{marginLeft: '20px', fontSize: '2em', cursor: 'pointer'}}
                        onClick={this.showInfo}>&#9432;</span>
                    </div>
            </div>
            <ClassifierBuilder tree={this.state.tree} dataset={this.state.dataset} visualiseRules
            editable visualisationType={this.state.visStyle}/>
            <Dialog open={this.state.showInfo}
            onClose={this.closeInfo}
            >
                <div style={{padding: '20px'}}>
                    <p>This software allows you to build a decision tree classifier for any dataset
                        you would like to load in. When loading a dataset, please ensure that:
                    </p>
                    <ul>
                        <li>The file is formatted as a csv using , as a delimiter.</li>
                        <li>The first row of the csv file is a header row containing a
                            name for each attribute and a class name.
                        </li>
                        <li>The class of each instance is the last column of the csv file.</li>
                    </ul>
                    <p>
                        If you'd like an introduction to the visualisation techniques used with
                        this software, check out my tutorials on decision tree classifiers.
                    </p>
                </div>
            </Dialog>
            </>
        )
    }
}