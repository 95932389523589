import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import "./Home.css"

export default class HomePage extends React.Component {
    render(): React.ReactNode {
        return (
            <div className="Home">
                <NavBar/>
                <div className="HomeContainer">
                    <div>
                        <h1>Hey, I'm Eugene</h1>
                        <p>
                            I've got a passion for AI, Machine Learning and all things tech!
                            During my PhD, my research
                            focused on building Machine Learning models
                            that remain interpretable to humans by leveraging
                            human-in-the-loop-learning, effective data visualisation of
                            high-dimensional datasets and novel decision-tree-classifier
                            induction algorithms to build powerful models that
                            remain understandable.
                        </p>
                        <p>
                            I'm also particularly interested in MLOps, robotics,
                            computer vision and data visualisation.
                        </p>
                        <p>
                            I'm always excited to collaborate on exciting projects and explore
                            new opportunities. If you have a fascinating project in mind or want to
                            connect, feel free to
                            <Link className='ContactLink' to='/contact'> Reach Out</Link> to me!
                            
                        </p>
                    </div>
                    <img src='/me.jpg'/>
                </div>
                <Footer/>
            </div>
        )
    }
}