import React from "react";
import SpeechBubble from './components/SpeechBubble/SpeechBubble';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Colour, Dataset, Attribute } from "@eugene-gilmore/classifier-builder";

export function shuffle(array : any[]) {
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export function infoBubble(element : string, text : string, offsetX : number, offsetY : number, onClick : (forward : boolean) => void, showBack : boolean = true) {
    let rect = document.getElementById(element)?.getBoundingClientRect()
    if(rect === undefined) {
        return (<div/>)
    }
    rect.x += window.pageXOffset + offsetX*rect.width
    rect.y += window.pageYOffset + offsetY*rect.height
    console.log(rect)
    let leftBubble = offsetX < 0.5
    return (
        <div style={{position: 'absolute',
        left: rect.x,
        top: rect.y,
        zIndex: 20,
        transform: leftBubble ? 'translate(-110%, -50%)' : 'translate(10%, -50%)'}}>
            <SpeechBubble leftArrow={leftBubble}>
                <p style={{marginTop: '10px', marginBottom: '10px'}}>{text}</p>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {showBack && <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => onClick(false)}>
                        <ArrowBackIosIcon/>
                        <p style={{margin: '0px'}}>{' Back'}</p>
                    </div>}
                    {!showBack && <div/>}
                    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => onClick(true)}>
                        <p style={{margin: '0px'}}>{'Next '}</p>
                        <ArrowForwardIosIcon/>
                    </div>
                </div>
            </SpeechBubble>
        </div>
    )
}

export function getID() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; prolificID=`);
    if (parts.length === 2){
        return parts.pop()!.split(';').shift()!;
    }
    return null
  }

export function getFormattedAttributeNames(attributes : Attribute[]) {
    let abbreviations = new Set<string>()
    let result = attributes.map( a => {
        let headerName = a.name.replace(/_/g, ' ').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
        var abbreviation = ''
        let minLength = Math.min(...headerName.split(' ').map(h => h.length))
        for(let i = 0; i<minLength; ++i) {
            abbreviation = headerName.split(' ').map(w => w.charAt(0).toUpperCase() + w.substr(1, i) + '.').reduce( (p,c) => p + ' ' + c).trimEnd()
            if(!abbreviations.has(abbreviation)) {
                abbreviations.add(abbreviation)
                break
            }
        }
        headerName = headerName + ' (' + abbreviation + ')'
        return {
            headerName,
            abbreviation
        }
    })
    return result
}

export function datasetColumns(attributes : Attribute[], className : string = 'Class') {
    let formattedNames = getFormattedAttributeNames(attributes)
    let result = attributes.map( (a,i) => {
        return {
            field: a.name,
            headerName: formattedNames[i].headerName,
            editable: false,
            width: 150
        }
    })
    result.push({
        field : 'class',
        headerName : className,
        editable : false,
        width : 150
    })
    return result
}

export function datasetRows(data: Dataset) {
    return data.instances.map( (instance,index) => {
        let row : any = {
            id: index,
            class: data.classes[instance.classIndex],
            attributes: instance.values,
            colour : Colour.forIndex(instance.classIndex)
        }
        return row
    })
}

export function dataTable(data: Dataset, className : string = 'Class', showClassColour : boolean = false, showClass : boolean = true) {
    var columns = datasetColumns(data.attributes, className)
    if(!showClass) {
        columns.pop()
    }
    return (
        <TableContainer style={{ maxHeight: '100%' }} component={Paper}>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        {columns.map(c => (
                            <TableCell key={c.field}>{c.headerName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datasetRows(data).map(r => (
                        <TableRow key={r.id}>
                            {r.attributes.map((a : number, i : number) => (
                                <TableCell key={i}>{a}</TableCell>
                            ))}
                            {showClass && <TableCell style={showClassColour ? {backgroundColor: r.colour.toRGBString()} : {}}>{r.class}</TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}