import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { GLParallelCoordinates, Dataset, AxisSelectionRule, PathToNode, Rule, TreeNode } from '@eugene-gilmore/classifier-builder';
import { getID, infoBubble, shuffle } from './Util';
import { Link } from 'react-router-dom';

interface State {
    stage : number
    iris : Dataset,
    irisTree : TreeNode,
    selectedInstance? : number,
}

export default class ParallelCoordinatesIntroPage extends React.Component<{}, State> {
    state : State = {
        stage : 0,
        iris : new Dataset(''),
        irisTree : new TreeNode(true)
    }

    componentDidMount() {
        axios.get('iris.csv').then(irisRes => {
            let iris = new Dataset(irisRes.data)
            iris.instances = shuffle(iris.instances)
            iris.classes = ['Iris Setosa', 'Iris Versicolor', 'Iris Virginica']

            let tree = new TreeNode(true)
            tree.rules = [new Rule([new AxisSelectionRule(2, undefined, 2.45)])]
            tree.children = [new TreeNode(), new TreeNode()]
            tree.children[0].parent = tree
            tree.children[1].parent = tree
            tree.children[1].rules = [new Rule([new AxisSelectionRule(3, undefined, 1.75)])]
            tree.children[1].children = [new TreeNode(), new TreeNode()]
            tree.children[1].children[0].parent = tree.children[1]
            tree.children[1].children[1].parent = tree.children[1]
            this.setState({
                iris : iris,
                irisTree : tree
            })
        })
    }

    datasetColumns() {
        return [...this.state.iris.attributes.map( a => {
            let headerName = a.name.replace(/_/g, ' ').replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
            headerName = headerName + ' (' + headerName.split(' ').map(w => w.charAt(0).toUpperCase()+'.').reduce( (p,c) => p + ' ' + c).trimEnd() + ')'
            return {
                field: a.name,
                headerName: headerName,
                editable: false,
                width: 150
            }
        }),
        {
            field : 'species',
            headerName : 'Species',
            editable : false,
            width : 150
        }
        ]
    }

    datasetRows() {
        let sn = this.state.irisTree.selectedNode()
        let data = this.state.iris
        if(sn) {
            data = Dataset.fromDatasetWithRule(this.state.iris, new PathToNode(sn))
        }
        return data.instances.map( (instance,index) => {
            let row : any = {
                id: index,
                species: this.state.iris.classes[instance.classIndex]
            }
            for(let a = 0; a < this.state.iris.attributes.length; ++a) {
                row[this.state.iris.attributes[a].name] = instance.values[a]
            }
            return row
        })
    }

    incrementStage = (forward : boolean = true) => {
        let stage = this.state.stage + (forward ? 1 : -1)
        this.setState({
            stage: stage
        })
        if(stage === 10) {
            //next page
        }
    }

    onNodeClick = (node : TreeNode) => {
        let sn = this.state.irisTree.selectedNode()
        if(sn) {
          sn.selected = false
        }
        node.selected = true
        this.forceUpdate()
    }

    infoBubble(element : string, text : string, offsetX : number, offsetY : number, back : boolean = true) {
        return infoBubble(element, text, offsetX, offsetY, this.incrementStage, back)
    }

    mouseOverRow = (index : number) => () => {
        this.setState({
            selectedInstance: index
        })
    }
    
    render() {
        return (
            <div style={{paddingLeft: '240px', paddingRight: '240px'}}>
                <Grid container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <h1>Parallel Coordinates</h1>
                    </Grid>
                    <Grid item>
                        {this.state.stage === 0 && <p style={{maxWidth: '600px'}}>
                            Listing data in a table is not the best way to discover patterns or trends.
                            Ideally, we'd like to get a better understanding of the Iris data by
                            visualising it.
                            Since we have four different attributes in the dataset (the width and
                            length of the petal and sepal), this can be a bit difficult using
                            traditional visualisation techniques like a scatter plot.
                            This is where Parallel Coordinates comes in. Let's see how we can
                            visualise the Iris dataset with Parallel Coordinates.
                        </p>}
                    </Grid>
                    {this.state.stage === 0 && <Grid item>
                        <Button color='primary' variant='contained' onClick={() => this.incrementStage(true)}>Continue</Button>
                    </Grid>}
                    <Grid item>
                        <Grid style={{ visibility: this.state.stage > 0 ? 'visible' : 'hidden' }} container spacing={5} direction="row" justifyContent="center" alignItems="center">
                            <Grid item>
                                <div id='iris-table' style={{ width: '500px', height: '400px' }}>
                                    <TableContainer style={{ maxHeight: '100%' }} component={Paper}>
                                        <Table stickyHeader size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    {this.datasetColumns().map(c => (
                                                        <TableCell key={c.field}>{c.headerName}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.datasetRows().map(r => (
                                                    <TableRow hover key={r.id} onMouseOver={this.mouseOverRow(r.id)}>
                                                        <TableCell>{r.sepal_length}</TableCell>
                                                        <TableCell>{r.sepal_width}</TableCell>
                                                        <TableCell>{r.petal_length}</TableCell>
                                                        <TableCell>{r.petal_width}</TableCell>
                                                        <TableCell>{r.species}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{width: '700px'}} id='iris-pcoords'>
                                    <GLParallelCoordinates dataset={this.state.iris}
                                    highlightedInstance={this.state.selectedInstance !== undefined ?
                                        this.state.iris.instances[this.state.selectedInstance] :
                                        undefined}></GLParallelCoordinates>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.stage > 8 && <Grid item>
                        <div style={{display: 'flex'}}>
                            <Button style={{margin: '5px'}} color='primary' variant='contained' onClick={(e : any) => this.incrementStage(false)}>Back</Button>
                            <Link to='/dtcpcord'>
                                <Button style={{margin: '5px'}} color='primary' variant='contained' onClick={(e : any) => this.incrementStage(true)}>Continue</Button>
                            </Link>
                        </div>
                    </Grid>}
                </Grid>
                {this.state.stage === 1 && this.infoBubble('iris-pcoords', 
                'Here the Iris dataset is being visualised using Parallel Coordinates.', 0, 0.1, false)}
                {this.state.stage === 2 && this.infoBubble('pcoordaxis-petal_width', 
                'Each attribute of the dataset is represented by a vertical axis.', 1, 0.5)}
                {this.state.stage === 3 && this.infoBubble('pcoordaxis-petal_width', 
                'Each instance in the dataset is represented by a coloured line that crosses through each axis.', 0.5, 10)}
                {this.state.stage === 4 && this.infoBubble('pcoordaxis-petal_width', 
                'The point that an instance\'s line crosses an axis corresponds to its value for that attribute.', 0.5, 10)}
                {this.state.stage === 5 && this.infoBubble('pcoordaxis-petal_width', 
                'The colour of the line indicates what class that instance belongs to.', 0.5, 10)}
                {this.state.stage === 6 && this.infoBubble('axis-mark-toggle', 
                'You can use the axis mark toggle to turn marks for the axes on or off.', 0, 0.5)}
                {this.state.stage === 7 && this.infoBubble('class-button-0', 
                'Each class has a corresponding button that can be used to show or hide that class.', 0, 0.5)}
                {this.state.stage === 8 && this.infoBubble('iris-table', 
                'You can hover your mouse over an instance in the table to see how it is mapped to the Parallel Coordinates visualisation. Give it a try and click continue when you feel comfortable with how data is visualised with Parallel Coordinates.', 1, 0.4)}
            </div>
        )
    }
}