import React from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import "./Publications.css"

export default class PublicationsPage extends React.Component {
    render(): React.ReactNode {
        return (
            <div className='Publications'>
                <NavBar/>
                <div className="PublicationsContainer"> 
                    <h1>Publications</h1>
                    <ul>
                        <li>
                            <a href='https://link.springer.com/chapter/10.1007/978-3-030-61534-5_14'>Constructing Interpretable Decision Trees Using Parallel Coordinates</a>
                        </li>
                        <li>
                            <a href='https://ieeexplore.ieee.org/document/9283240'>Human-In-The-Loop Construction of Decision Tree Classifiers with Parallel Coordinates</a>
                        </li>
                        <li>
                            <a href='https://link.springer.com/chapter/10.1007/978-3-030-86271-8_24'>More Interpretable Decision Trees</a>
                        </li>
                    </ul>
                </div>
                <Footer/>
            </div>
        )
    }
}