import React from 'react';
import './SpeechBubble.css'

interface Props {
    leftArrow? : boolean
}

export default class SpeechBubble extends React.Component<Props,{}> {
    render() {
        return (
            <div className={'speech-bubble' + (this.props.leftArrow ? ' speech-bubble-left' : ' speech-bubble-right')}>
                {this.props.children}
            </div>
        )
    }
}