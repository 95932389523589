import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css'
import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import ContactPage from './components/pages/Contact';
import HILLToolPage from './components/pages/HILLTool';
import HomePage from './components/pages/Home';
import PublicationsPage from './components/pages/Publications';
import DecisionTreeIntroPage from './dtcintro/DecisionTreeIntroPage';
import DecisionTreeWithPcoordIntroPage from './dtcintro/DecisionTreeWithPcoordIntroPage';
import HILLIntroPage from './dtcintro/HILLIntroPage';
import ParallelCoordinatesIntroPage from './dtcintro/ParallelCoordinatesIntroPage';
import StartPage from './dtcintro/StartPage';

function AppPage (props : {page : any}) {
  return (
    <div className='App'>
      <NavBar/>
      <div className='MainContent'>
        {props.page}
      </div>
      <Footer/>
    </div>
  )
}

function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/contact' element={<ContactPage/>}/>
        <Route path='/publications' element={<PublicationsPage/>}/>
        <Route path='/tutorial-start' element={<AppPage page={<StartPage/>}/>}/>
        <Route path='/dtcintro' element={<AppPage page={<DecisionTreeIntroPage/>}/>}/>
        <Route path='/pcordintro' element={<AppPage page={<ParallelCoordinatesIntroPage/>}/>}/>
        <Route path='/dtcpcord' element={<AppPage page={<DecisionTreeWithPcoordIntroPage/>}/>}/>
        <Route path='/hillintro' element={<AppPage page={<HILLIntroPage/>}/>}/>
        <Route path='/hilltool' element={<AppPage page={<HILLToolPage/>}/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
