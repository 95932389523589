import React from "react";
import { Link } from "react-router-dom";
import './NavBarMenu.css'

type NavBarMenuItem = {
    name : string,
    path : string,
    external? : boolean
}

interface NavBarMenuProps {
    visible? : boolean
    title : string
    items : NavBarMenuItem[]
}

export default class NavBarMenu extends React.Component<NavBarMenuProps, {}> {

    state = {
        hoverTitle : false,
        hoverItem : false
    }

    setHoverItem = (hover : boolean) => {
        this.setState({
            hoverItem : hover
        })
    }

    setHoverTitle = (hover : boolean) => {
        this.setState({
            hoverTitle : hover
        })
    }



    render(): React.ReactNode {
        let selected = this.state.hoverItem || this.state.hoverTitle ? ' selected' : ''
        return (
            <div onMouseEnter={() => this.setHoverTitle(true)} onMouseLeave={() => this.setHoverTitle(false)}>
                <li className={'NavBar-item' + selected}>
                    <div className='NavBar-menu'>
                        {this.props.title} <i className='fas fa-caret-down' />
                    </div>
                </li>
                <div className={'NavBar-menu-dropdown' + selected}>
                    <ul onMouseEnter={() => this.setHoverItem(true)} onMouseLeave={() => this.setHoverItem(false)}>
                        {this.props.items.map((item,i) =>
                            <li key={i}>
                                {item.external &&
                                <a className='NavBar-menu-link' href={item.path}>
                                    {item.name}
                                </a>}
                                {!item.external &&
                                <Link className='NavBar-menu-link' to={item.path}>
                                    {item.name}
                                </Link>}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}