import React from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import './Contact.css'

export default class ContactPage extends React.Component {
    decodeEmail(encodedString : string) {
        var email = ""; 
        var keyInHex = encodedString.substr(0, 2);
        var key = parseInt(keyInHex, 16);

        for (var n = 2; n < encodedString.length; n += 2) {
            var charInHex = encodedString.substr(n, 2)
            var char = parseInt(charInHex, 16);
            var output = char ^ key;
            email += String.fromCharCode(output);
        }
        return email;
    }
    
    render(): React.ReactNode {
        return (
            <div className="Contact">
                <NavBar/>
                <div className='ContactContainer'>
                    <div>
                    <h1>Contact</h1>
                    <p>Feel free to reach out to me.
                        The best way of contacting me is via <a href={'mailto:' + this.decodeEmail('2a4945445e4b495e6a4f5f4d4f444f074d43464745584f04494547')}>email</a>.
                        You can also find me on <a href='https://www.linkedin.com/in/eugene-gilmore'>LinkedIn</a>.</p>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}