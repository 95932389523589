import { Button, Grid, MenuItem, Select} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { ClassifierBuilder, VisualisationType, Dataset, TreeNode } from '@eugene-gilmore/classifier-builder';
import { infoBubble } from './Util';

interface State {
    stage : number
    iris : Dataset,
    irisTree : TreeNode,
    stageAfter200 : boolean,
    visStyle : number
}

export default class HILLIntroPage extends React.Component<{}, State> {
    state : State = {
        stage : 0,
        iris : new Dataset(''),
        irisTree : new TreeNode(true),
        stageAfter200 : true,
        visStyle : VisualisationType.ParallelCoordinates
    }

    componentDidMount() {
        axios.get('iris.csv').then(irisRes => {
            let iris = new Dataset(irisRes.data)
            iris.classes = ['Iris Setosa', 'Iris Versicolor', 'Iris Virginica']

            let tree = new TreeNode(true)
            this.setState({
                iris : iris,
                irisTree : tree,
            })
        })
    }

    incrementStage = (forward : boolean = true) => {
        let stage = this.state.stage + (forward ? 1 : -1)
        let newState : any = {
            stage: stage,
            stageAfter200: false
        }

        if(stage === 6) {
            newState.visStyle = VisualisationType.ScatterPlot
            newState.irisTree = new TreeNode(true)
        }
        if(stage === 5) {
            newState.visStyle = VisualisationType.ParallelCoordinates
            newState.irisTree = new TreeNode(true)
        }
        this.setState(newState)
        setTimeout(() => {this.setState({stageAfter200 : true})}, 300)
    }

    onNodeClick = (node : TreeNode) => {
        let sn = this.state.irisTree.selectedNode()
        if(sn) {
          sn.selected = false
        }
        node.selected = true
        this.forceUpdate()
    }

    infoBubble(element : string, text : string, offsetX : number, offsetY : number) {
        return infoBubble(element, text, offsetX, offsetY, this.incrementStage)
    }

    setVis = (event : any) => {
        this.setState({
            visStyle: event.target.value,
            irisTree: new TreeNode(true)
        })
    }
    
    render() {
        return (
            <div style={{paddingLeft: '240px', paddingRight: '240px'}}>
                <Grid container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <h1>Human-in-the-loop learning of Decision Tree Classifiers</h1>
                    </Grid>
                    {this.state.stage === 0 && <Grid item>
                        <p style={{maxWidth: '600px'}}>
                            Up to this point, each decision tree classifier that we have seen has been
                            created using an machine-learning algorithm. In this section, we consider
                            the ability of a human to assist in building a decision tree classifier.
                            Here we discuss two different visualisation styles we can use to build decision tree classifiers. 
                            Let's have a look at them.
                        </p>
                    </Grid>}
                    {this.state.stage === 0 && <Grid item>
                        <Button color='primary' variant='contained' onClick={() => this.incrementStage()}>Continue</Button>
                    </Grid>}
                    <Grid item>
                    </Grid>
                </Grid>
                <div style={{ visibility: this.state.stage > 0 ? 'visible' : 'hidden'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <p id='vis-style-menu' style={{marginRight: '10px'}}>Visualisation Style:</p>
                        <Select disabled={this.state.stage<4} onChange={this.setVis} value={this.state.visStyle}>
                            <MenuItem value={VisualisationType.ParallelCoordinates}>Parallel Coordinates</MenuItem>
                            <MenuItem value={VisualisationType.ScatterPlot}>Scatter Plot</MenuItem>
                        </Select>
                    </div>
                    
                    <ClassifierBuilder dataset={this.state.iris} tree={this.state.irisTree}
                    weight percentageWeight noAxisReorder noAxisRescale noControls editable
                    visualiseRules={false} colouredNodes={true} visualisationType={this.state.visStyle}></ClassifierBuilder>
                </div>
                <Grid style={{ visibility: this.state.stage > 8 ? 'visible' : 'hidden'}} container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <p style={{maxWidth: '600px'}}>
                            Once your familiar with how this human-in-the-loop system works feel free
                            to use it for your own datasets! You can find a standalone version of this
                            tool under my projects menu.
                        </p>
                    </Grid>
                    <Grid item>
                        <Button color='primary' variant='contained' onClick={() => this.incrementStage(false)}>Back</Button>
                    </Grid>
                </Grid>
                {this.state.stage === 1 && this.state.stageAfter200 && this.infoBubble('dtcnode-root', 
                'The first visualisation style we use is an extension of the Parallel Coordinates system we have seen previously. Here we have the start of a decison tree classifier with only a root node.', 1, 0.5)}
                {this.state.stage === 2 && this.infoBubble('pcoordaxis-sepal_length', 
                'You can now click and drag on an axis in Parallel Coordinates to specify a split for the currently selected node.', 0, 1)}
                {this.state.stage === 3 && this.infoBubble('dtcnode-root', 
                'You can also right click on any node to delete the split of that node as well as all of the the node\'s children.', 0, 0.5)}
                {this.state.stage === 4 && this.infoBubble('axis-0-controls', 
                'Each axis in parallel coordinates now includes control to allow you to shift the axis to the left, right, start or end. There is also a button to delete the axis for attributes not of interest.', 0, 0.5)}
                {this.state.stage === 5 && this.infoBubble('vis-style-menu',
                'Instead of parallel coordinates we can use a Scatter Plot to visualise a dataset and build a decision tree classifier. You can use this menu to switch between visualisation techniques.', 0, 0.5)}
                {this.state.stage === 6 && this.infoBubble('scatter-x-menu',
                'When using a scatter plot you can use the two drop down menu\'s to select which pair of attributes are shown in the scatter plot.', 0, 0.5)}
                {this.state.stage === 7 && this.infoBubble('scatter-x-menu',
                'When using a scatter plot you can create a split for a node by clicking and dragging to select a region in the scatter plot.', 0, 0.5)}
                {this.state.stage === 8 && this.infoBubble('dtcnode-root', 
                'Have a go at building a decision tree classifier yourself for the Iris dataset using the two different visualisation techniques.', 1, 0.5)}
            </div>
        )
    }
}