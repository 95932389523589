import { Button, Grid} from '@mui/material';
import axios from 'axios';
import React from 'react';
import {DecisionTree, Dataset, AxisSelectionRule, PathToNode, Rule, TreeNode} from '@eugene-gilmore/classifier-builder';
import { dataTable, getID, infoBubble, shuffle } from './Util';
import { Link } from 'react-router-dom';

interface State {
    stage : number
    iris : Dataset,
    irisTree : TreeNode
}

export default class DecisionTreeIntroPage extends React.Component<{}, State> {
    state = {
        stage : 1,
        iris : new Dataset(''),
        irisTree : new TreeNode(true),
    }

    componentDidMount() {
        axios.get('iris.csv').then(irisRes => {
            let iris = new Dataset(irisRes.data)
            iris.instances = shuffle(iris.instances)
            iris.classes = ['Iris Setosa', 'Iris Versicolor', 'Iris Virginica']

            let tree = new TreeNode(true)
            tree.rules = [new Rule([new AxisSelectionRule(2, undefined, 2.45)])]
            tree.children = [new TreeNode(), new TreeNode()]
            tree.children[0].parent = tree
            tree.children[1].parent = tree
            tree.children[1].rules = [new Rule([new AxisSelectionRule(3, undefined, 1.75)])]
            tree.children[1].children = [new TreeNode(), new TreeNode()]
            tree.children[1].children[0].parent = tree.children[1]
            tree.children[1].children[1].parent = tree.children[1]
            this.setState({
                iris : iris,
                irisTree : tree,
            })
        })
    }

    infoBubble(element : string, text : string, offsetX : number, offsetY : number, showBack : boolean = true) {
        return infoBubble(element, text, offsetX, offsetY, this.incrementStage, showBack)
    }

    incrementStage = (forward : boolean = true) => {
        let stage = this.state.stage + (forward ? 1 : -1)
        if(!forward && stage === 7) {
            stage = 6
        } 
        this.setState({
            stage: stage
        })
        if(forward && stage === 7) {
            setTimeout(this.incrementStage, 300)
        }
        if(!forward && stage === 6) {
            setTimeout(() => this.forceUpdate(), 300)
        }
        if(stage === 19) {
            this.onNodeClick(this.state.irisTree)
        }
        if(stage === 20) {
            this.onNodeClick(this.state.irisTree.children[1].children[1])
        }
        if(stage === 21) {
            this.onNodeClick(this.state.irisTree.children[1].children[1])
        }
        if(stage === 35) {
            //next page
        }
        else if(stage === 29) {
            this.onNodeClick(this.state.irisTree.children[0])
        }
        else if(stage === 30) {
            this.onNodeClick(this.state.irisTree.children[1].children[0])
        }
    }

    onNodeClick = (node : TreeNode) => {
        let sn = this.state.irisTree.selectedNode()
        if(sn) {
          sn.selected = false
        }
        node.selected = true
        this.forceUpdate()
    } 
    
    render() {
        let sn = this.state.irisTree.selectedNode()
        let data = this.state.iris
        if(sn) {
            data = Dataset.fromDatasetWithRule(this.state.iris, new PathToNode(sn))
        }
        return (
            <div style={{paddingLeft: '240px', paddingRight: '240px'}}>
                <Grid container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <h1 id='title'>Tutorial - Decision Tree Classifiers</h1>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={5} direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <div id='iris-table' style={{ width: '500px', height: '400px' }}>
                                    {dataTable(data, 'Species', this.state.stage > 25)}
                                </div>
                            </Grid>
                            {this.state.stage > 6 && <Grid item>
                                <div style={{width: '500px'}} id='iris-dtc'>
                                <DecisionTree dataset={this.state.iris} node={this.state.irisTree}
                                colouredNodes={this.state.stage > 25} onNodeClick={this.onNodeClick}
                                textRule percentageWeight attributeAbrivations={['S.L.', 'S.W.', 'P.L.', 'P.W.']}
                                height='450px'></DecisionTree>
                                </div>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{ visibility: this.state.stage > 32 ? 'visible' : 'hidden'}} container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <p style={{maxWidth: '600px'}}>
                            In the next section we'll have a look at Parallel Coordinates. When you are ready, click continue.
                        </p>
                    </Grid>
                    <Grid item>
                    <div style={{display: 'flex'}}>
                            <Button style={{margin: '5px'}} color='primary' variant='contained' onClick={(e : any) => this.incrementStage(false)}>Back</Button>
                            <Link to='/pcordintro'>
                                <Button style={{margin: '5px'}} color='primary' variant='contained' onClick={(e : any) => this.incrementStage(true)}>Continue</Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
                {this.state.stage === 1 && this.infoBubble('iris-table', 
                'This table shows measurements taken from 150 different Iris flowers.', 0, 0.1, false)}
                {this.state.stage === 2 && this.infoBubble('iris-table', 
                'Each row in the table represents one Iris flower (one instance in the dataset).',
                0, 0.1)}
                {this.state.stage === 3 && this.infoBubble('iris-table',
                'Four measurements have been taken for each instance in this dataset. These are the length and width of the flower sepal and the length and width of the flower petal.', 0, 0.1)}
                {this.state.stage === 4 && this.infoBubble('iris-table',
                'Each Iris flower belongs to one of three species, Setosa, Versicolor or Virginica. In general we use the term \'class\' to refer to the category an instance belongs to.', 1, 0.1)}
                {this.state.stage === 5 && this.infoBubble('iris-table',
                'We would like to find a way of using this data to predict what species an Iris flower belongs to by only knowing its Sepal and Petal measurements.', 1, 0.1)}
                {this.state.stage === 6 && this.infoBubble('iris-table',
                'One way to do this is with a decision tree classifier. Let\'s have a look at one for the Iris dataset.', 1, 0.1)}
                {this.state.stage === 8 && this.infoBubble('iris-dtc',
                'This diagram shows a decision tree classifier that has been learnt from the Iris data.', 0, 0.1)}
                {this.state.stage === 9 && this.infoBubble('iris-dtc',
                'This decision tree classifier was created by passing all of the Iris data to an algorithm designed to build decision trees classifiers.', 0, 0.1)}
                {this.state.stage === 10 && this.infoBubble('iris-dtc',
                'Let\'s see how a decision tree classifier is used to determine what class an instance belongs to.', 0, 0.1)}
                {this.state.stage === 11 && this.infoBubble('dtcnode-root',
                'Every decision tree classifier starts at the root node at the top of the tree. This is where we ask our first question. Each node in a decision tree has text to represent the question at that node. Below the rule each node also includes a percentage. This percentage indicates the number of instances that reach this node.', 0, 0.5)}
                {this.state.stage === 12 && this.infoBubble('dtcnode-root',
                'In this decision tree classifier, we first check if the Petal Length is less than 2.45.', 0, 0.5)}
                {this.state.stage === 13 && this.infoBubble('dtcnode-0',
                'If the instance we are classifying satisfies the test, we move to the left child node. The left child node is linked to its parent with a green line.', 0, 0.5)}
                {this.state.stage === 14 && this.infoBubble('dtcnode-1',
                'Otherwise, we move to the right child node. The right child node is linked to its parent with a black line.', 1, 0.5)}
                {this.state.stage === 15 && this.infoBubble('dtcnode-1',
                'If the node we reach has another question, we repeat this process.', 1, 0.5)}
                {this.state.stage === 16 && this.infoBubble('dtcnode-01',
                'We continue until the node we reach has no child nodes. This is called a leaf node.', 0, 0.5)}
                {this.state.stage === 17 && this.infoBubble('dtcnode-01',
                'Each leaf node classifies instances that reach it as belonging to a certain class. This class is displayed as text on the node. Like internal nodes, below this text there is also a number between 0% and 100%. This represents the percentage of instances in the dataset that reach this node.', 0, 0.5)}
                {this.state.stage === 18 && this.infoBubble('dtcnode-root',
                'For larger decision trees the entire tree may not fit within the display pane. You can click and drag anywhere in the display pane to move the tree around and focus on different parts of it.', 0, 0.1)}
                {this.state.stage === 19 && this.infoBubble('dtcnode-root',
                'The black outline around the root node indicates that it is the node that is currently selected. You can change which node is currently selected by clicking on any node in the tree.', 0, 0.1)}
                {this.state.stage === 20 && this.infoBubble('dtcnode-11',
                'By selecting a node in the decision tree classifier only instances that reach that node will be shown in the table on the left. Give it a try before clicking next.', 1, 0.5)}
                {this.state.stage === 21 && this.infoBubble('dtcnode-11',
                'To determine the class that a leaf node assigns to instances that reach it, we look at the data used to build the decision tree classifier.', 0, 0.5)}
                {this.state.stage === 22 && this.infoBubble('dtcnode-11',
                'By selecting the node the table only shows which instances of the dataset reach this node. We can determine the class of the leaf node by observing which class appears the most out of these instances.', 0, 0.5)}
                {this.state.stage === 23 && this.infoBubble('dtcnode-11',
                'Of all the instances from the dataset that reach this leaf node, Virginica instances appear the most.', 1, 0.5)}
                {this.state.stage === 24 && this.infoBubble('dtcnode-11',
                'So the decision tree classifier predicts that any instance that reaches this node belongs to the Virginica species.', 1, 0.5)}
                {this.state.stage === 25 && this.infoBubble('dtcnode-root', 
                'Let\'s also use some colour to show information about the instances reaching each node.', 1, 0.5)}
                {this.state.stage === 26 && this.infoBubble('dtcnode-root', 
                'Each node in the tree now uses colors in proportion to the number of instances of that class reaching the node. This colouring is also reflected in the table. From the table we can see that blue has been used for Setosa, red for Versicolor and green for Virginica.', 1, 0.5)}
                {this.state.stage === 27 && this.infoBubble('dtcnode-root', 
                'Let\'s now look at how we can get an estimate of how accurately a decision tree classifier predicts the class of each instance. We can do this by looking at the distribution of instances arriving at each leaf node.', 1, 0.5)}
                {this.state.stage === 28 && this.infoBubble('dtcnode-root', 
                'For any leaf node we can determine how often it classifies an instance incorrectly by looking at the number of instances that reach it whose class does not match the class label of the leaf node. We can also use the colouring of the leaf node to easily get a quick estimate of this.', 1, 0.5)}
                {this.state.stage === 29 && this.infoBubble('dtcnode-0', 
                'For this leaf node, we can see that all instances that reach it belong to the same class, and as such, all instances from the training dataset that reach this node will be classified correctly.', 1, 0.5)}
                {this.state.stage === 30 && this.infoBubble('dtcnode-01', 
                'For this leaf node, most of the instances that reach it are from the same class. However, there is also a few instances of the Virginica class reaching this node that will be incorrectly classified as members of the Versicolor class.', 1, 0.5)}
                {this.state.stage === 31 && this.infoBubble('dtcnode-root', 
                'When considering the overall accuracy of the decision tree classifier, it is important to consider both the accuracy of each leaf node as well as the number of instances that reach it.', 1, 0.5)}
                {this.state.stage === 32 && this.infoBubble('dtcnode-root', 
                'The number of instances that reach a leaf node weights the impact of that leaf when computing the overall accuracy of the tree.', 1, 0.5)}
            </div>
        )
    }
}